














import Vue from 'vue'
import {
  mapActions,
  mapMutations,
  mapGetters,
  mapState
} from 'vuex'
import Loading from '@/components/Loading/index.vue'
import ValidatedBFormInput from '@/components/ui/ValidatedBFormInput.vue'
import ValidatedBFormMaskedInput from '@/components/ui/ValidatedBFormMaskedInput.vue'
import { ProfileRequisites } from '@/store/modules/profiles/types'
import { cloneDeep } from '@/utils/functions'
import Requisites from '@/components/Requisites/Requisites.vue'

export default Vue.extend({
  name: 'ProfileRequisites',

  components: {
    Loading,
    ValidatedBFormInput,
    ValidatedBFormMaskedInput,
    Requisites
  },

  data () {
    return {
      loading: false,
      saving: false,
      requisites: {} as ProfileRequisites
    }
  },

  computed: {
    ...mapState('common', [
      'businessCategories'
    ]),

    ...mapState('profiles', [
      'profile'
    ]),

    ...mapGetters('profiles', [
      'getNewRequisites'
    ])
  },

  created () {
    this.requisites = this.getNewRequisites()
  },

  mounted () {
    this.getRequisites()
  },

  methods: {
    ...mapActions('profiles', [
      'getProfileRequisites',
      'createProfileRequisites',
      'updateProfileRequisites'
    ]),

    ...mapMutations('profiles', [
      'setProfileRequisites'
    ]),

    async getRequisites () {
      try {
        this.loading = true
        const res = await this.getProfileRequisites(this.profile.profile_data.id)
        this.requisites = res
      } catch (error) {
        const newRequisites = cloneDeep(this.getNewRequisites())

        switch (error.response.status) {
          case 404:
            this.setProfileRequisites(newRequisites)
            break
          default:
            this.$toast.error(error.message)
            break
        }
      } finally {
        this.loading = false
      }
    },

    async handleOnSubmit (localRequisites) {
      this.loading = true
      const payload = { profileId: this.profile.profile_data.id, requisites: localRequisites }

      if (this.requisites.id) {
        const result = await this.updateProfileRequisites(payload)
        if (result.status < 200 || result.status >= 300) {
          this.$toast.error(result.data.detail.message)
          this.loading = false
          return
        }
      } else {
        const result = await this.createProfileRequisites(payload)
        if (result.status < 200 || result.status >= 300) {
          this.$toast.error(result.data.detail.message)
          this.loading = false
          return
        }
      }

      this.loading = false
    }
  }
})
